var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LitElement, html, customElement, property } from 'lit-element';
import ResizeObserver from '@juggle/resize-observer';
import { loadingTemplate, mythicImageLayersTemplate, nonMythicImageLayersTemplate, baseArtworkLayersTemplate, textLayersTemplate, } from './templating';
import './assets/fonts.css';
import { getStyles } from './styles';
// @TODO: these should really come from an endpoint call,
// so that we can easily update them in the future...
export const legacyQualities = [
    'plain',
    // @NOTE: there may be "0" quality items in future, for now, these items
    // can use the plain layer imagery assets
    'plain',
    'bronze',
    'iron',
    'meteorite',
    'shadow',
    'gold',
    'diamond'
];
export const qualities = [
    'diamond',
    'gold',
    'shadow',
    'meteorite',
    'plain',
];
// Deploy a native ResizeOberver for this component instance:
const ro = new ResizeObserver(entries => {
    entries.forEach(entry => {
        const el = entry.target;
        el.handleResize(entry);
    });
});
/**
 *
 * GU Composited Card Web Component
 * -----------------------------------------------------------------
 *
 * A simple, framework agnostic web component to facilitate the
 * display of Gods Unchained card element(s).
 *
 * @customElement
 * @demo https://immutable.github.io/gu-composited-card/
 *
 * @input protoId
 * @input quality
 * @input inputProtoData
 * @input responsiveSrcsetSizes
 * @input useLegacyQualityMapping
 *
 * @author Tim Paul <tim.paul@immutable.com> <@glomotion>
 *
 */
let CompositedCard = class CompositedCard extends LitElement {
    constructor() {
        super();
        this.quality = 5;
        this.useLegacyQualityMapping = false;
        this.protoCardData = {
            type: '',
            effect: '',
            name: '',
            rarity: '',
            god: '',
            set: '',
            mana: null,
            id: null,
            attack: null,
            health: null,
            tribe: '',
        };
        this.loading = true;
        this.quality = 0;
        this.ch = this.offsetHeight * 0.01;
        this.cw = this.offsetWidth * 0.01;
    }
    static get styles() {
        return getStyles();
    }
    /**
     * Generic LitElement component life-cycle events
     */
    connectedCallback() {
        super.connectedCallback();
        ro.observe(this);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        ro.unobserve(this);
    }
    updated(changedProps) {
        changedProps.forEach((oldValue, propName) => {
            if (propName === 'protoId') {
                this.getProtoDataFromApi();
            }
            else if (propName === 'inputProtoData') {
                this.getProtoDataFromInput();
            }
        });
    }
    /**
     * Generic resize handling
     */
    handleResize(event) {
        const container = event.target.shadowRoot.children[0];
        this.ch = container.offsetHeight * 0.01;
        this.cw = container.offsetWidth * 0.01;
        this.requestUpdate();
    }
    /**
     * A method to fetch a protoId's card info data
     */
    fetchProtoData() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            return fetch(`https://api.godsunchained.com/v0/proto/${this.protoId}`).then(resp => resp.json());
        });
    }
    /**
     * A method to handle the fetching, and then processing
     * of proto card data
     */
    getProtoDataFromApi() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.fetchProtoData().then(data => {
                const { id, type, attack, health, effect, name, rarity, god, mana, set, tribe, } = data;
                this.protoCardData = {
                    id,
                    type,
                    attack: attack.Int64,
                    health: health.Int64,
                    effect,
                    name,
                    rarity,
                    god,
                    mana,
                    set,
                    tribe: tribe.String,
                };
                this.loading = false;
                this.requestUpdate();
                return data;
            });
        });
    }
    /**
     * A method for Injesting of proto card data that is manually
     * input into the component
     */
    getProtoDataFromInput() {
        this.protoCardData = Object.assign({}, this.inputProtoData);
        this.loading = false;
        this.requestUpdate();
    }
    /**
     * A `render` method to define the DOM structure of the component
     */
    render() {
        const qualityName = this.useLegacyQualityMapping
            ? legacyQualities[this.quality]
            : qualities[this.quality - 1];
        const isMythicCard = this.protoCardData.rarity === 'mythic';
        return html `
      <div class="card__innerRatioConstrainer">
        ${this.loading
            ? loadingTemplate()
            : html `
              ${baseArtworkLayersTemplate({
                id: this.protoCardData.id,
                responsiveSrcsetSizes: this.responsiveSrcsetSizes,
            })}
              ${isMythicCard
                ? mythicImageLayersTemplate({
                    type: this.protoCardData.type,
                    qualityName: qualityName,
                    responsiveSrcsetSizes: this.responsiveSrcsetSizes,
                })
                : nonMythicImageLayersTemplate(Object.assign({ qualityName: qualityName, responsiveSrcsetSizes: this.responsiveSrcsetSizes }, this.protoCardData))}
              ${textLayersTemplate(Object.assign({ ch: this.ch, cw: this.cw }, this.protoCardData))}
            `}
      </div>
    `;
    }
};
__decorate([
    property({ type: Number })
], CompositedCard.prototype, "protoId", void 0);
__decorate([
    property({ type: Number })
], CompositedCard.prototype, "quality", void 0);
__decorate([
    property({ type: Object })
], CompositedCard.prototype, "inputProtoData", void 0);
__decorate([
    property({ type: String })
], CompositedCard.prototype, "responsiveSrcsetSizes", void 0);
__decorate([
    property({ type: Boolean })
], CompositedCard.prototype, "useLegacyQualityMapping", void 0);
CompositedCard = __decorate([
    customElement('composited-card')
], CompositedCard);
export { CompositedCard };
